.fullscreenGraphiqlWrapper {
  max-width: 100% !important;
  display: flex;
  border-radius: 1.5rem;
  border: none !important;
  overflow: hidden;
}

.fixedHeightWrapper {
  min-height: 500px;
  height: 85vh !important;
}

.fullscreenGraphiqlWrapper .CodeMirror pre,
.fullscreenGraphiqlWrapper .history-contents,
.fullscreenGraphiqlWrapper .CodeMirror-hint {
  font-family: "Space Mono" !important;
}

.fullscreenGraphiqlWrapper .doc-explorer-title-bar,
.fullscreenGraphiqlWrapper .history-title-bar,
.fullscreenGraphiqlWrapper .CodeMirror-hint-information .content {
  height: 50px !important;
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  font-weight: normal !important;
}

.fullscreenGraphiqlWrapper .doc-explorer-title-bar,
.fullscreenGraphiqlWrapper .history-title-bar {
  cursor: default;
  display: flex;
  padding: 5px 8px 8px 5px;
  position: relative;
  user-select: none;
}

.fullscreenGraphiqlWrapper .topBar .execute-button-wrap {
  margin-left: 0 !important;
}

.fullscreenGraphiqlWrapper .topBarWrap .topBar {
  height: 50px;
}

.fullscreenGraphiqlWrapper .docExplorerShow {
  background: #f6f6f6 !important;
  color: black !important;
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  font-weight: 500 !important;
}

.fullscreenGraphiqlWrapper .docExplorerShow {
  margin-top: 7px;
}

.fullscreenGraphiqlWrapper .docExplorerHide {
  margin-top: 7px;
}

.fullscreenGraphiqlWrapper .docExplorerHide:hover {
  color: white !important;
}

.fullscreenGraphiqlWrapper .doc-explorer-rhs .docExplorerShow:hover {
  color: white !important;
}

.fullscreenGraphiqlWrapper .docExplorerShow:hover {
  background: rgb(229, 231, 235) !important;
  color: rgb(81, 80, 104) !important;
}

.fullscreenGraphiqlWrapper .docExplorerShow:before {
  border-color: rgba(24, 23, 55) !important;
  margin: 0 3px 2px 0 !important;
  height: 6px !important;
  width: 6px !important;
}

/* Docs explorer */
.fullscreenGraphiqlWrapper .doc-explorer .doc-explorer-title {
  display: none;
  text-align: center;
}

.fullscreenGraphiqlWrapper .doc-explorer-contents {
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
}

.fullscreenGraphiqlWrapper .doc-explorer-contents a {
  word-break: break-all;
}

.fullscreenGraphiqlWrapper .search-box {
  padding-bottom: 5px;
}

.fullscreenGraphiqlWrapper .search-box .search-box-icon {
  margin-right: 10px;
}

.fullscreenGraphiqlWrapper .search-box > input[type="text"] {
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  border-radius: 4px !important;
  width: 88%;
}

.fullscreenGraphiqlWrapper .doc-explorer .doc-explorer-back {
  color: white;
  padding-left: 10px;
}

.fullscreenGraphiqlWrapper .doc-explorer .doc-explorer-back::before {
  border-color: white;
}

.fullscreenGraphiql {
  flex-grow: 1;
  min-width: 75%;
  display: flex;
  flex-direction: row;
  height: 100% !important;
  background-color: #000;
  overflow: hidden;
}

/* New Values! */
.editorWrap {
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.fullscreenEditorWrap {
  flex: 1;
  flex-direction: row;
  height: 75vh !important;
  width: 100% !important;
  border-radius: 15px;
  display: flex;
  overflow: hidden;
}

.graphiqlFullscreenContainer {
  height: 100%;
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important;
  flex: 1;
  overflow: hidden !important;
  background: #000 !important;
}

.graphiqlFullscreenContainer .graphiqlContainer {
  height: 50% !important;
}

.graphiqlContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden !important;
  background: #000 !important;
}

.editorWrap .CodeMirror {
  height: 100% !important;
}

.graphiqlHalf .CodeMirror {
  height: 100% !important;
}

.CodeMirror {
  background-color: black !important;
  font-size: 15px !important;
  line-height: 1.2rem !important;
  background: #000 !important;
}

.CodeMirror-sizer {
  overflow: clip !important;
}

.fullscreenGraphiql .CodeMirror-sizer {
  padding-left: 15px !important;
}

.CodeMirror-info {
  border-radius: 8px !important;
  padding: 12px !important;
  font-size: 15px !important;
  line-height: 1.2rem !important;
  background-color: black !important;
  border: 1px solid #1f2937;
}

.CodeMirror pre {
  font-family: "Space Mono", monospace !important;
}

.graphiqlExecute {
  position: absolute;
  bottom: 25px;
  text-align: center;
  height: 40px;
  right: 135px;
  border-radius: 20px;
  width: 40px;
  z-index: 10;
}

.mutation {
  right: 155px;
}

.execute-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.execute-button svg {
  margin-left: 4px;
}

.graphiqlSectionHeader {
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  position: absolute;
  z-index: 2;
}

.CodeMirror-gutters {
  display: none;
}

.CodeMirror-gutter-wrapper {
  display: none;
}

.editorWrap .CodeMirror-scroll {
  padding-left: 1rem;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 20px !important;
  height: 100% !important;
}

.editorWrap .CodeMirror-scroll::-webkit-scrollbar {
  display: none;
}

.fullscreenGraphiql .variableContainer .CodeMirror-vscrollbar,
.fullscreenGraphiql .resultContainer .CodeMirror-vscrollbar {
  display: none !important;
}

.editorWrap .CodeMirror-vscrollbar,
.editorWrap .CodeMirror-hscrollbar {
  display: none !important;
}

.CodeMirror-selected {
  background-color: #404040 !important;
}

.cm-property {
  color: rgb(128, 203, 196) !important;
}

.cm-string {
  color: rgb(165, 232, 68) !important;
}

.cm-variable {
  color: rgb(255, 102, 102) !important;
}

.cm-attribute {
  color: rgb(255, 203, 107) !important;
}

.cm-def {
  color: rgb(199, 146, 234) !important;
}

.cm-keyword {
  color: rgb(199, 146, 234) !important;
}

.cm-atom {
  color: rgb(242, 255, 0) !important;
}

.cm-punctuation {
  color: #666 !important;
}

.cm-number {
  color: rgb(253, 145, 112) !important;
}

.fullscreenGraphiqlWrapper .docExplorerWrap {
  background: #000 !important;
  overflow: auto;
  min-width: 25%;
  border-bottom: 1px solid #1f2937;
}

.fullscreenGraphiqlWrapper .doc-explorer-back {
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 4px;
}

.fullscreenGraphiqlWrapper .doc-explorer-back:hover {
  text-decoration: underline;
}

.doc-explorer-narrow .doc-explorer-back {
  width: 0;
}

.fullscreenGraphiqlWrapper .doc-explorer-back:before {
  border-left: 2px solid #3b5998;
  border-top: 2px solid #3b5998;
  content: "";
  display: inline-block;
  height: 10px;
  transform: rotate(-45deg);
  width: 10px;
}

.fullscreenGraphiqlWrapper .doc-explorer-rhs {
  margin-left: auto;
}

.fullscreenGraphiqlWrapper .doc-explorer-contents,
.fullscreenGraphiqlWrapper .history-contents {
  border-top: 1px solid #1f2937;
  padding: 20px 15px;
  top: 47px;
}

.fullscreenGraphiqlWrapper .doc-type-description p:first-child,
.fullscreenGraphiqlWrapper .doc-type-description blockquote:first-child {
  margin-top: 0;
}

.fullscreenGraphiqlWrapper .doc-explorer-contents a {
  cursor: pointer;
  text-decoration: none;
}

.fullscreenGraphiqlWrapper .doc-explorer-contents a:hover {
  text-decoration: underline;
}

.fullscreenGraphiqlWrapper .doc-value-description > :first-child {
  margin-top: 4px;
}

.fullscreenGraphiqlWrapper .doc-value-description > :last-child {
  margin-bottom: 4px;
}

.fullscreenGraphiqlWrapper .doc-type-description code,
.fullscreenGraphiqlWrapper .doc-type-description pre,
.fullscreenGraphiqlWrapper .doc-category code,
.fullscreenGraphiqlWrapper .doc-category pre {
  --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  font-size: 12px;
  line-height: 1.50001;
  font-variant-ligatures: none;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
}

.fullscreenGraphiqlWrapper .doc-type-description code,
.fullscreenGraphiqlWrapper .doc-category code {
  padding: 2px 3px 1px;
  border: 1px solid var(--saf-0);
  border-color: rgb(156, 163, 175);
  border-radius: 3px;
  background-color: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  color: rgb(156, 163, 175);
  background-color: transparent;
}

.fullscreenGraphiqlWrapper .doc-category {
  margin: 20px 0;
}

.fullscreenGraphiqlWrapper .doc-category-title {
  border-bottom: 1px solid #1f2937;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0 -15px 10px 0;
  padding: 5px 0;
  user-select: none;
}

.fullscreenGraphiqlWrapper .doc-category-item {
  margin: 12px 0;
  color: rgb(156, 163, 175);
}

.fullscreenGraphiqlWrapper .keyword {
  color: rgb(199, 146, 234) !important;
}

.fullscreenGraphiqlWrapper .type-name {
  color: rgb(128, 203, 196) !important;
}

.fullscreenGraphiqlWrapper .field-name {
  color: rgb(199, 146, 234) !important;
}

.fullscreenGraphiqlWrapper .field-short-description {
  color: rgb(156, 163, 175);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}

.fullscreenGraphiqlWrapper .enum-value {
  color: #ffcb6b !important;
  word-break: break-all;
}

.fullscreenGraphiqlWrapper .arg-name {
  color: #a5e844 !important;
}

.fullscreenGraphiqlWrapper .arg {
  display: block;
}

.fullscreenGraphiqlWrapper .arg:first-child:last-child,
.fullscreenGraphiqlWrapper .arg:first-child:nth-last-child(2),
.fullscreenGraphiqlWrapper .arg:first-child:nth-last-child(2) ~ .arg {
  display: inherit;
  margin: inherit;
}

.fullscreenGraphiqlWrapper .arg:first-child:nth-last-child(2):after {
  content: ", ";
}

.fullscreenGraphiqlWrapper .arg-default-value {
  color: #a5e844 !important;
}

.fullscreenGraphiqlWrapper .doc-deprecation:before {
  content: "Deprecated";
  color: #f0314b;
  cursor: default;
  display: block;
  padding-bottom: 5px;
  margin-top: 7px;
  user-select: none;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.fullscreenGraphiqlWrapper .search-box {
  border-bottom: 1px solid #1f2937;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: -15px -15px 12px 0;
  position: relative;
}

.fullscreenGraphiqlWrapper .search-box-icon {
  cursor: pointer;
  display: block;
  font-size: 24px;
  transform: rotate(-45deg);
  user-select: none;
}

.fullscreenGraphiqlWrapper .search-box .search-box-clear {
  display: none;
}

.fullscreenGraphiqlWrapper .search-box > input {
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
  width: 100%;
  background-color: #000;
}

.fullscreenGraphiqlWrapper .error-container {
  font-weight: bold;
  left: 0;
  letter-spacing: 1px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(0, -50%);
}

.CodeMirror-sizer {
  min-height: 100px !important;
}

/* BEN FIX !Epic */
.editorWrap .CodeMirror-sizer + div {
  display: none;
}

.graphiqlHalf {
  width: 50% !important;
  flex-basis: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important;
  overflow-x: scroll;
  position: relative;
  border: 1px solid #1f2937;
}

.fullscreenGraphiql .graphiqlHalf {
  overflow-x: auto;
}

.CodeMirror-wrap pre {
  word-break: break-all !important;
}

.graphiqlColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: none !important;
}
.queryContainer,
.resultContainer,
.variableContainer {
  flex: 1;
  overflow: auto;
  flex-basis: auto;
  min-height: 30% !important;
}

.variableContainer {
  position: relative;
}

.fullscreenGraphiql .CodeMirror .CodeMirror-cursor {
  border-left: 3px solid #55f5a3;
}

.editorWrap .CodeMirror .CodeMirror-cursor {
  border-left: 3px solid #55f5a3;
}

.query-editor {
  height: 100% !important;
  padding-top: 50px;
}

#graphiql-result-viewer {
  height: 100% !important;
  padding-top: 50px;
}

.codemirrorWrap {
  padding-top: 50px;
}
